import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../slices/userSlice';
import companiesReducer from '../slices/companiesSlice';
import projectsReducer from '../slices/projectsSlice';
import projectReducer from '../slices/projectSlice';
import commitmentsReducer from '../slices/commitmentsSlice';
import loginReducer from '../slices/loginSlice';
import vendorsReducer from '../slices/vendorsSlice';
import vendorDetailReducer from '../slices/vendorDetailSlice';
import customCategoriesReducer from '../slices/customCategoriesSlice';
import emailRequestSlice from '../slices/emailRequestSlice';
import companyEmployeesReducer from '../slices/companyEmployeesSlice';

export const store = configureStore({
  reducer: {
    customCategories: customCategoriesReducer,
    companies: companiesReducer,
    companyEmployees: companyEmployeesReducer,
    emailRequest: emailRequestSlice,
    projects: projectsReducer,
    project: projectReducer,
    commitments: commitmentsReducer,
    counter: counterReducer,
    login: loginReducer,
    user: userReducer,
    vendorDetail: vendorDetailReducer,
    vendors: vendorsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
