import { useMobileView } from '../../hooks/use-mobile-view';
import MobileHeaderNav from './MobileHeaderNav';
import DesktopHeaderNav from './DesktopHeaderNav';
import { useOauthState } from '../../hooks/use-oauth-state';

const HeaderNavContainer = () => {
   
    // This component will always be present so we'll handle oauth state here
    useOauthState()

    const isMobile = useMobileView();

    return (
        isMobile ? 
            <MobileHeaderNav /> :
            <DesktopHeaderNav />
    )

};

export default HeaderNavContainer;
