import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectCompanies } from "../../slices/companiesSlice";
import { getCompanyProjectThunk, selectProject, selectProjectStatus } from "../../slices/projectSlice";
import { selectVendorDetailState } from "../../slices/vendorDetailSlice";

//TODO: Support Commitments

interface ICrumb {
    path: string;
    linkText: string;
    isActive: boolean;
}

/*
*Because this component does not have its own route,
* We have to parse ids ourselves instead of using UseParams.
* Life is suffering.
*/
const parseId = (pathSegments: string[], expectedPrecedingSegment: string) => {
    const idPosition = pathSegments.indexOf(expectedPrecedingSegment) + 1;
    if (idPosition < pathSegments.length) {
        const maybeId = parseInt(pathSegments[idPosition]);
        return isNaN(maybeId) ? null : maybeId;
    } 

    return null;
};

/*
* Dynamically renders url path into breadcrumbs
*/
const NavBreadCrumbs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companies = useAppSelector(selectCompanies);
    const project = useAppSelector(selectProject);
    const { vendor } = useAppSelector(selectVendorDetailState);
    const projectStatus = useAppSelector(selectProjectStatus)
    const { pathname } = useLocation();
    const [crumbs, setCrumbs] = useState<Array<ICrumb>>([]);

    let pathSegments: string[] = pathname.substring(1).split('/');
    const maybeCompanyId = parseId(pathSegments, 'companies');
    const maybeProjectId = parseId(pathSegments, 'projects');

    /*
        Matches a numerical id route segment to its corresponding entity (company, project, etc).
        We use previous segment to determine entity type.
    */
    const findEntityName = (id: number, previousSegment: string): string => {
        switch(previousSegment) {
            case 'companies':
                const company = companies.find(co => co.id === id);
                return company ? company.name.toLowerCase() : id.toString();
            case 'projects':
                return project ? project.name.toLowerCase() : id.toString();
            case 'vendors':
                return vendor ? vendor.name.toLocaleLowerCase() : id.toString();
            default:
                return id.toString();
        }
    };

    /*
    * Converts each url segment into a crumb
    */
    const parseCrumbs = () =>  pathSegments.reduce((acc: ICrumb[], ps: string, i) => {
        
        // Build the path by adding new segment to previous path
        const path = i === 0 ? 
            '/' + ps :
            acc[i -1]?.path + '/' + ps;

        // If a url segment is an id, convert it to the corresponding name
        const maybeEntityId = parseInt(ps);
        const linkText = (isNaN(maybeEntityId) || i < 1) ? ps : findEntityName(maybeEntityId, acc[i-1].linkText);

        // If this is the last url segment, we'll set it to active.
        const isFinalSegment = i === pathSegments.length -1;

        const newCrumb = {
            path,
            linkText: linkText,
            isActive: isFinalSegment
        } as ICrumb;

        return (
            [...acc, newCrumb]
        )
    }, []);


    useEffect(() => {
        const parsedCrumbs = parseCrumbs();
        setCrumbs(parsedCrumbs);
    }, [pathname, project, companies, vendor])

    useEffect(() => {
        // Depending on the navigation path, the project may not be in state yet.
        // We might need it for the project name.
        if (
            maybeCompanyId
            && maybeProjectId
            && project?.id !== maybeProjectId
            && projectStatus === 'idle'
        ) {
            dispatch(getCompanyProjectThunk({ companyId: maybeCompanyId, projectId: maybeProjectId }));
        }

    }, [maybeCompanyId, maybeProjectId])

    // Currently we only display this component for companies flow.
    if (!pathname.includes('companies')) {
        return null;
    }

    return (
        <StyledBreadCrumb data-testid='nav-bread-crumb'>
            <Breadcrumb.Section link onClick={() => navigate('/')}>home</Breadcrumb.Section>
            {
                crumbs.map((crumb) => {
                    return (
                        <React.Fragment key={crumb.linkText}>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section 
                                link 
                                onClick={() => navigate(crumb.path)}
                                active={crumb.isActive}
                            >
                            {crumb.linkText}
                            </Breadcrumb.Section>
                        </React.Fragment>
                    )
                })
            }
        </StyledBreadCrumb>
    );
}

const StyledBreadCrumb = styled(Breadcrumb)`

    &&& {
        margin-bottom: 25px;
        @media (max-width: 768px) {
            margin-top: 36px;
            margin-bottom: 16px;
        }
    }
`;

export default NavBreadCrumbs;
