import axios from "axios";
import { parseQueryState } from "../../utils/Sorting";
import { getEnvVariable, isInIframe } from "../../utils/System";
import { ICheckoutSessionResponse, ICreateCustomCategoryResponse, IGetCustomCategoriesResponse, ISearchableResponse, IServiceAccountResponse, IUserResponse } from "../interfaces/IApiResponses";
import { ICommitment } from "../interfaces/ICommitment";
import { ICompany } from "../interfaces/ICompany";
import { ICustomRatingCategory } from "../interfaces/ICustomRatingCategory";
import { IProject } from "../interfaces/IProject";
import { IRating, IRatingSubmission, IRatingSubmissionsResponse } from '../interfaces/IRating';
import { IQueryParams } from "../interfaces/ISortableTable";
import { IVendor } from "../interfaces/IVendor";
import { ICompanyEmployee } from "../../slices/companyEmployeesSlice";

const baseUrl = getEnvVariable('REACT_APP_SUB_RATING_API_BASE_URL');

const subRatingApi = axios.create({
    baseURL: baseUrl,
    /*
    * The Embedded header will let the server know which redirect url to use.
    * /confirmation or /embed/confirmation
     */
    headers: {
        Embedded: isInIframe().toString(),
    },
    withCredentials: true
});

export async function login(code: string): Promise<IUserResponse> {
    const result = await subRatingApi.get(`/callback?code=${code}`);
    return result.data;
}

export async function logout(): Promise<null> {
    const result = await subRatingApi.get('logout');
    return result.data;
}

export async function getCheckoutSession(companyId: number): Promise<ICheckoutSessionResponse> {
    const { data } = await subRatingApi.get(`companies/${companyId}/checkout-session/`);
    return data;
}

export async function getUser(): Promise<IUserResponse> {
    const result = await subRatingApi.get('/user');
    return result.data;
}

export async function activateTrial(companyId: number): Promise<Array<ICompany>> {
    const { data } = await subRatingApi.get(`/companies/${companyId}/activate-trial`);
    return data;
}

export async function updateCompanySubscription(companyId: number): Promise<Array<ICompany>> {
    const { data } = await subRatingApi.get(
        `/companies/${companyId}/cancel-subscription`,
    );
    return data;
}

export async function getCompanyProjects(companyId: number, queryParams?: IQueryParams): Promise<ISearchableResponse<IProject[]>> {
    const result = await subRatingApi.get(
        `/companies/${companyId}/projects`,
        {params: queryParams}
    );
    const queryState = parseQueryState(result.headers, queryParams);
    return { data: result.data, queryState: queryState };
}

export async function getCompanyEmployees(companyId: number): Promise<ICompanyEmployee[]> {
    const result = await subRatingApi.get(
        `/companies/${companyId}/employees/`,
    );
    return result.data;
}

export async function getCompanyServiceAccountStatus(companyId: number): Promise<IServiceAccountResponse> {
    const result = await subRatingApi.get(
        `companies/${companyId}/service-account`
    );

    return result.data;
}

export async function getCompanyProject(companyId: number, projectId: number): Promise<IProject> {
    const result = await subRatingApi.get(`/companies/${companyId}/projects/${projectId}`);
    return result.data;
}

export async function getProjectCommitments(companyId: number, projectId: number, queryParams?: IQueryParams): Promise<ISearchableResponse<Array<ICommitment>>> {
    const result = await subRatingApi.get(
        `/companies/${companyId}/projects/${projectId}/commitments`,
        { params: queryParams}
    );
    const queryState = parseQueryState(result.headers, queryParams);
    return { data: result.data, queryState: queryState };
}

export async function getProjectVendors(companyId: number, projectId: number, searchParams?: IQueryParams): Promise<ISearchableResponse<Array<IVendor>>> {
    const result = await subRatingApi.get(
        `/companies/${companyId}/projects/${projectId}/vendors/`,
        {params: searchParams}
    );

    const queryState = parseQueryState(result.headers, searchParams);

    return { data: result.data, queryState: queryState };
}

export async function getCompanyVendors(companyId: number, searchParams?: IQueryParams): Promise<ISearchableResponse<Array<IVendor>>> {
    const result = await subRatingApi.get(
        `/companies/${companyId}/vendors/`,
        {params: searchParams }
    );

    const queryState = parseQueryState(result.headers, searchParams);

    return { data: result.data, queryState: queryState };
}

export async function getVendorRatings(companyId: number, vendorId: number, queryParams?: IQueryParams): Promise<ISearchableResponse<Array<IRating>>> {
    const result = await subRatingApi.get(
        `companies/${companyId}/vendors/${vendorId}/ratings`,
        { params: queryParams }
    );
    
    const queryState = parseQueryState(result.headers, queryParams);

    return { data: result.data, queryState: queryState };
}

export async function getVendorDetail(companyId: number, vendorId: number): Promise<IVendor> {
    const result = await subRatingApi.get(`companies/${companyId}/vendors/${vendorId}`);
    return result.data;
}

export async function submitVendorRating(companyId: number, vendorId: number, rating: IRatingSubmission): Promise<IRatingSubmissionsResponse> {
    const result = await subRatingApi.post(`companies/${companyId}/vendors/${vendorId}/ratings/`, rating);
    return result.data;
}

export async function getCustomCategories(companyId: number): Promise<IGetCustomCategoriesResponse> {
    const { data } = await subRatingApi.get(`companies/${companyId}/custom-rating-categories/`);
    return { companyId, data };
}

export async function createCustomCategory(companyId: number, category: ICustomRatingCategory): Promise<ICreateCustomCategoryResponse> {
    const { data } = await subRatingApi.post(`companies/${companyId}/custom-rating-categories/`, category);
    return { companyId, data };
}

export async function deleteCustomCategory(companyId: number, categoryId: number): Promise<{id: number}> {
    const result = await subRatingApi.delete(`companies/${companyId}/custom-rating-categories/${categoryId}/`);
    return result.data;
}

export async function editCustomCategory(companyId: number, category: ICustomRatingCategory): Promise<number> {
    const result = await subRatingApi.put(`companies/${companyId}/custom-rating-categories/${category.id}/`, category);
    return result.data;
}

export async function reprioritizeCustomCategory(companyId: number, categories: ICustomRatingCategory[]) {
    const result = await subRatingApi.post(`companies/${companyId}/custom-rating-categories/update/`, { categories: categories });
    return result;
}

export async function requestRecordsByEmail(url: string): Promise<any> {
    const result = await subRatingApi.get(url);
    return result.data;
}

export async function inviteUser(companyId: number, userId: number ): Promise<any> {
    const result = await subRatingApi.post(`companies/${companyId}/email/`, { userId });
    return result.data;
}

export async function getCommitmentVendor(companyId: number, projectId: number, commitmentId: number): Promise<IVendor> {
    const result = await subRatingApi.get(`companies/${companyId}/projects/${projectId}/commitments/${commitmentId}/vendor`);
    return result.data;
}