import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionContent, AccordionTitle, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SubscriptionStatus } from '../../app/interfaces/ICompany';
import { activateTrialThunk, selectCompanies, checkServiceAccountStatusThunk, selectCheckCompanyServiceAccountStatus } from '../../slices/companiesSlice';
import { StyledInlineButton, StyledLi, StyledUl } from '../CommonStyledComponents';
import CompanyAdmin from '../companies/CompanyAdmin';
import CompanyServiceAccountStatusModal from '../companies/CompanyServiceAccountModal';
import CustomRatingCategories from '../CustomRatingCategories/CustomRatingCategories';
import { InviteUsersModal } from './InviteUsersModal';

const CompanyDetail = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const companyId = parseInt(params?.companyId!);
    const company = useAppSelector(selectCompanies).find(co => co.id === companyId);
    const checkCompanyServiceAccountRequestStatus = useAppSelector(selectCheckCompanyServiceAccountStatus);
    const [isServiceAccountModalOpen, setIsServiceAccountModalOpen] = useState(false);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [accordionExpandedName, setAccordionExpandedName] = useState('');

    const expandOrContractAccordions = (accordionName: string) => {
        if (accordionExpandedName === accordionName) {
            setAccordionExpandedName('');
        } else {
            setAccordionExpandedName(accordionName);
        }
    };

    const onCheckServiceAccountStatus = () => {
        dispatch(checkServiceAccountStatusThunk(companyId));
        setIsServiceAccountModalOpen(true);
    };

    if (!company) {
        return null;
    }

    return (
        <div id='company'>
            {
                isServiceAccountModalOpen &&
                <CompanyServiceAccountStatusModal
                    companyId={company.id}
                    onClose={() => setIsServiceAccountModalOpen(false)}
                    requestStatus={checkCompanyServiceAccountRequestStatus}
                />
            }

            {
                isInviteModalOpen &&
                <InviteUsersModal company={company} onClose={() => setIsInviteModalOpen(false)} />
            }

            <h1>{company?.name}</h1>
            {
                company.hasServiceAccount ?
                <div>
                    Service Account Activated&nbsp;
                    <Icon color='green' name='check circle' /> 
                </div> :
                <div>
                    <a href={`https://app.procore.com/${company.id}/company/admin/app_installations`} target="_blank" rel="noreferrer">Install Sub Ratings Service Account on Procore</a> <a href="/help"><Icon fitted name='help circle' /></a>
                    <StyledInlineButton size='tiny' onClick={onCheckServiceAccountStatus}>Re-check Status</StyledInlineButton>
                </div>
            }

            <StyledUl>
                <StyledLi>
                    <Link to={`/companies/${company.id}/projects`}>View Projects</Link>
                </StyledLi>
                <StyledLi>
                    <Link to={`/companies/${company.id}/vendors`}>View/Rate Vendors</Link>
                </StyledLi>
                <StyledLi>
                    {
                        company.subscriptionStatus === SubscriptionStatus.None ?

                        <StyledButton positive onClick={() => dispatch(activateTrialThunk(company.id))}>Begin Trial</StyledButton> :
                        <Link to={`/companies/${company.id}/admin`}>Subscription Settings</Link>
                    }           
                </StyledLi>
                <StyledLi>
                    <Button
                        onClick={() => setIsInviteModalOpen(true)}
                    >
                        Invite users
                    </Button>
                </StyledLi>
            </StyledUl>
            
            <Accordion>
                <AccordionTitle
                    onClick={() => expandOrContractAccordions('admin')}
                    active={accordionExpandedName === 'admin'}
                >
                    <h4><Icon name='dropdown' /> Subscription Settings</h4>
                </AccordionTitle>
                <AccordionContent active={accordionExpandedName === 'admin'} >
                    <CompanyAdmin />
                </AccordionContent>
            </Accordion>

            {
                company.subscriptionStatus !== SubscriptionStatus.None ? (
                    <Accordion >
                        <AccordionTitle
                            onClick={() => expandOrContractAccordions('categories')}
                            active={accordionExpandedName === 'categories'}
                        >
                            <h4><Icon name='dropdown' /> Custom Rating Categories</h4>
                        </AccordionTitle>
                        <AccordionContent active={accordionExpandedName === 'categories'}>
                            <CustomRatingCategories />
                        </AccordionContent>
                    </Accordion>
                ) : <h4>Subscribe to Sub Ratings to view or create custom categories.</h4>
            }
        </div>
    )
};



const StyledButton = styled(Button)`
    &&& {
        margin-top: 20px;
    }
`;

export default CompanyDetail;

